import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from "react-ga4";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactGA.initialize("G-XENPRLC7BK", {testMode: true});

// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);


const SendAnalytics = () => {
    console.log('Here', window.location.pathname);
    ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname,
    });
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// @ts-ignore
reportWebVitals(SendAnalytics);
